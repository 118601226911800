import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { timeDiff } from 'utils';

import { useCurrentTime } from 'hooks';

const Timer = ({ startTime }) => {
  const currTime = useCurrentTime();
  if (!startTime) {
    return '00:00:00';
  }

  if (startTime > currTime) {
    return '00:00:00';
  }

  return timeDiff(startTime, currTime).formatted;
};

Timer.propTypes = {
  startTime: PropTypes.number,
};

Timer.defaultProps = {
  startTime: null,
};

export default observer(Timer);
