import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import 'components/config/i18n/i18next.config';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useGlobalContext, useTiltifyUpdates } from 'hooks';
import * as styles from 'components/global/styles';
import { JSONViewer, SetupModal } from 'components/admin';
import {
  CurrencyDollarIcon,
  InformationCircleIcon,
  FlagIcon,
  ChartBarIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  EyeIcon,
  EyeSlashIcon,
  PencilSquareIcon,
  ShieldExclamationIcon,
  Cog6ToothIcon,
  PlayIcon,
  StopIcon,
  ArrowPathIcon,
  CheckCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';
import Timer from 'components/Timer';

const GavelIcon = () => {
  return (
    <svg
      className="w-6 h-6"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z" />
    </svg>
  );
};

const twitchChat =
  process.env.NODE_ENV === 'production'
    ? 'https://www.twitch.tv/embed/noresetspeedrun/chat?parent=host.noreset.tv&darkpopout'
    : 'https://www.twitch.tv/embed/noresetspeedrun/chat?parent=localhost&darkpopout';

const Page = () => {
  const store = useGlobalContext();
  const router = useRouter();
  const { id } = router.query;
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [lang, setLang] = useState(i18next?.language || 'en');
  const { t } = useTranslation(['home']);
  const [isAdmin, setAdmin] = useState(0);
  useTiltifyUpdates();

  useEffect(async () => {
    let results = await store.getAdmin(id);
    setAdmin(results);
  }, []);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, [lang]);

  const handleLangMode = () => {
    setLang(lang === 'en' ? 'fr' : 'en');
  };

  const getPollOptions = object => {
    const options = [];

    const sorted = object.options
      .slice()
      .sort(function (a, b) {
        return a.collected - b.collected;
      })
      .reverse();

    for (const option of sorted)
      options.push({
        title: option.description.description,
        raised: parseFloat(option.collected).toFixed(2),
      });

    return options;
  };

  const getMilestoneOptions = object => {
    const max = object.goal;
    const perc = (store.getDonationTotal() / max) * 100;
    const range = `$${store
      .getDonationTotal()
      .toFixed(2)
      .toLocaleString('en-US')} / $${max.toLocaleString('en-US')} `;
    const remain = max - store.getDonationTotal();
    const percString = `[ ${perc.toFixed(2)}% ]`;
    if (store.getDonationTotal() >= object.goal) {
      return <p className="text-green-600 dark:text-green-300 text-sm font-bold">{t('goal')}</p>;
    }
    return (
      <p className={`${styles.textColorHeader} text-sm flex gap-2`}>
        {range}
        <div className="text-green-600 dark:text-green-300 text-sm font-bold">
          {percString}
        </div>{' '}
        <div className={`${styles.textColorHeader} text-sm font-bold`}>
          ${remain.toLocaleString('en-US')} to Goal
        </div>
      </p>
    );
  };

  const getStreamName = donation => {
    if (donation.stream !== undefined) {
      return (
        <div
          className={`text-center ${styles.roundTop} ${styles.bBorder} text-leet dark:text-yellow-500 font-bold ${styles.bgLightest} flex justify-center items-center gap-2`}
        >
          <div className="text-leet dark:text-yellow-500 w-4 h-4 rotate-90 scale-x-[-1]">
            <ChartBarIcon />
          </div>
          {donation.stream.poll.description.title}
        </div>
      );
    }
    return <div className={`text-center ${styles.roundTop} ${styles.textColorHeader}`} />;
  };

  const getStream = donation => {
    if (donation.stream !== undefined) {
      return (
        <div
          className={`text-center ${styles.roundBottom} ${styles.textColorHeader} ${styles.bgLight}`}
        >
          {donation.stream.poll.option.description.description}
        </div>
      );
    }
    return <div className={`text-center ${styles.textColorHeader}`} />;
  };

  const handleClickEvent = () => {
    setOpen(prev => !prev);
  };

  const handleClickEvent2 = () => {
    setOpen2(prev => !prev);
  };

  if (isAdmin === 0)
    return (
      <div className="dark w-full h-[100vh] grid grid-cols-1 grid-rows-1 bg-black p-4">
        <div
          className={`col-span-1 row-span-2 rounded-md ${styles.bgLight} flex flex-col overflow-hidden border-2 border-slate-500`}
        >
          <h1
            className={`${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-between items-center gap-2 pl-3`}
          >
            <div className="flex items-center gap-2">
              <div className="w-5 h-5">
                <CurrencyDollarIcon />
              </div>
              {t('donation')}
              <div className="text-green-300">${store.fundky.amount.toLocaleString('en-US')}</div>
            </div>
            <div className="flex gap-2 justify-between items-center">
              <div
                className={`${
                  lang === 'en' ? 'bg-en' : 'bg-fr'
                } bg-cover bg-center w-12 h-12 cursor-pointer m-2`}
                onClick={() => handleLangMode()}
              />
            </div>
          </h1>
          <div className="w-full overflow-x-hidden px-2">
            {store.fundky.donations
              .filter(d => store.getApproved(d.id))
              .map(d => (
                <div
                  key={d.id}
                  className={
                    store.getStatus(d.id)
                      ? `p-2 bg-slate-800 my-2 rounded-lg opacity-40`
                      : `p-2 bg-slate-900 my-2 rounded-lg`
                  }
                >
                  <h1
                    className={`${
                      store.getHidden(d.id) ? 'text-red-500' : 'text-white'
                    } font-bold flex justify-between ${
                      store.getStatus(d.id) ? 'line-through' : ''
                    }`}
                  >
                    <div className="flex gap-2">
                      <div
                        className={`${store.getHidden(d.id) ? 'text-red-500' : 'text-yellow-400'}`}
                      >
                        {store.getHidden(d.id) || d.anonymous == 1 ? 'Anonymous' : d.displayName}
                      </div>
                      <div className="text-white">-</div>
                      <div className="text-green-300">${d.amount}</div>
                    </div>
                  </h1>
                  {!store.fundky.donationStatus[d.id] == true && (
                    <>
                      <p className={`${styles.textColorDescription} text-sm`}>
                        {!store.getHidden(d.id) &&
                          !store.getStatus(d.id) &&
                          (d.message ?? d.message)}
                      </p>
                      {!store.getStatus(d.id) && (
                        <>
                          {getStreamName(d)}
                          {getStream(d)}
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  return (
    <div className="dark w-full lg:h-[100vh] h-full grid lg:grid-cols-3 grid-cols-1 gap-2 px-4 bg-black">
      {isAdmin > 2 && open && (
        <JSONViewer lang={lang} open={open} onClickHandler={handleClickEvent} />
      )}
      {isAdmin > 2 && open2 && (
        <SetupModal lang={lang} open={open2} onClickHandler={handleClickEvent2} />
      )}
      {/* Column 1 */}
      <div className="col-span-1 h-[96vh] grid grid-cols-1 grid-rows-3 gap-2 my-4">
        {/* Donation List */}
        <div
          className={`col-span-1 row-span-2 rounded-md ${styles.bgLight} flex flex-col overflow-hidden border-2 border-slate-500`}
        >
          <h1
            className={`${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-between items-center gap-2 pl-3`}
          >
            <div className="flex items-center gap-2">
              <div className="w-5 h-5">
                <CurrencyDollarIcon />
              </div>
              {t('donation')}
              <div className="text-green-300">
                ${store.getDonationTotal().toFixed(2).toLocaleString('en-US')}
              </div>
            </div>
            <div className="flex gap-2 justify-between items-center">
              {isAdmin > 2 && (
                <button
                  type="button"
                  className={`bg-slate-800 hover:bg-slate-700 rounded-lg w-10 h-8 flex justify-center items-center cursor-pointer border-2 border-slate-500 p-2`}
                  onClick={setOpen}
                >
                  <PencilSquareIcon />
                </button>
              )}
              <button
                type="button"
                className={`${
                  lang === 'en' ? 'bg-en' : 'bg-fr'
                } bg-cover bg-center w-12 h-8 cursor-pointer m-2`}
                onClick={() => handleLangMode()}
              />
            </div>
          </h1>
          <div className="w-full overflow-x-hidden px-2">
            {isAdmin > 0 &&
              store.fundky.donations.map(d => (
                <div
                  key={d.id}
                  className={
                    store.getStatus(d.id)
                      ? `p-2 bg-slate-800 my-2 rounded-lg opacity-40`
                      : `p-2 bg-slate-900 my-2 rounded-lg`
                  }
                >
                  <h1
                    className={`${
                      store.getHidden(d.id) ? 'text-red-500' : 'text-white'
                    } font-bold flex justify-between ${
                      store.getStatus(d.id) ? 'line-through' : ''
                    }`}
                  >
                    <div className="flex gap-2">
                      <div
                        className={`${store.getHidden(d.id) ? 'text-red-500' : 'text-yellow-400'}`}
                      >
                        {store.getHidden(d.id) || d.anonymous == 1 ? 'Anonymous' : d.displayName}
                      </div>
                      <div className="text-white">-</div>
                      <div className="text-green-300">${d.amount}</div>
                    </div>

                    <div className={`flex gap-2 ${styles.textColorHeader}`}>
                      {isAdmin > 1 && (
                        <button
                          type="button"
                          className={`${
                            store.getApproved(d.id)
                              ? 'text-green-400 hover:text-green-300'
                              : 'text-sky-400 hover:text-sky-300'
                          } w-6 h-6  cursor-pointer`}
                          onClick={() => store.setApproved(d.id)}
                        >
                          {store.getApproved(d.id) ? (
                            <ShieldCheckIcon />
                          ) : (
                            <ShieldExclamationIcon />
                          )}
                        </button>
                      )}
                      <button
                        type="button"
                        className={`${
                          store.getHidden(d.id)
                            ? 'text-red-400 hover:text-red-300'
                            : 'text-yellow-400 hover:text-yellow-300'
                        } w-6 h-6  cursor-pointer`}
                        onClick={() => store.setHidden(d.id)}
                      >
                        <GavelIcon />
                      </button>
                      <button
                        type="button"
                        className="w-6 h-6 hover:text-green-300 cursor-pointer"
                        onClick={() => store.setStatus(d.id)}
                      >
                        {store.getStatus(d.id) ? <EyeSlashIcon /> : <EyeIcon />}
                      </button>
                    </div>
                  </h1>
                  {!store.fundky.donationStatus[d.id] == true && (
                    <>
                      <p className={`${styles.textColorDescription} text-sm`}>
                        {!store.getHidden(d.id) &&
                          !store.getStatus(d.id) &&
                          (d.message ?? d.message)}
                      </p>
                      {!store.getStatus(d.id) && (
                        <>
                          {getStreamName(d)}
                          {getStream(d)}
                        </>
                      )}
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
        {/* Charity Info */}
        <div
          className={`col-span-1 row-span-1 rounded-md ${styles.bgLight} flex flex-col overflow-hidden border-2 border-slate-500`}
        >
          <h1
            className={`${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-between items-center gap-2 pl-3`}
          >
            <div className="flex justify-start items-center gap-2">
              <div className="w-5 h-5">
                <InformationCircleIcon />
              </div>
              <>{t('charity')}</>
            </div>
          </h1>
          <div
            className={`${styles.bgLight} w-full h-full overflow-auto p-2 flex flex-col justify-start items-start gap-2`}
          >
            <h1 className={`text-yellow-500 text-sm font-bold`}>
              {store.translations[lang].charity.name}
            </h1>
            {store.translations[lang].charity.info.length > 0 &&
              store.translations[lang].charity.info.map((i, idx) => (
                <div
                  key={idx}
                  className={`w-full ${styles.textColorHeader} ${styles.bgDark} text-sm p-2 rounded-lg`}
                >
                  <div className="w-full flex justify-between">
                    <h1 className={`text-yellow-500 text-sm font-bold`}>
                      {i.label}{' '}
                      <span className="text-red-400">
                        <Timer startTime={store.charityTimer[idx]} />
                      </span>
                    </h1>
                    <div className="w-12 h-5 flex justify-end cursor-pointer">
                      {store.charityTimer[idx] ? (
                        <StopIcon onClick={() => store.stopTimer(0, idx)} />
                      ) : (
                        <PlayIcon onClick={() => store.startTimer(0, idx)} />
                      )}
                      {store.charityTimer[idx] && (
                        <ArrowPathIcon onClick={() => store.resetTimer(0, idx)} />
                      )}
                    </div>
                  </div>
                  <p className="indent-8">{i.description}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* Column 2 */}
      <div className="col-span-1 h-[96vh] grid grid-rows-3 gap-2 my-4">
        {/* Milestones List */}
        <div
          className={`row-span-1 rounded-md ${styles.bgLight} flex flex-col overflow-hidden border-2 border-slate-500`}
        >
          <h1
            className={`${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-start items-center gap-2 pl-3`}
          >
            <div className="w-5 h-5">
              <FlagIcon />
            </div>
            <>{t('milestone')}</>
          </h1>
          <div className="w-full row-span-1 overflow-auto px-2">
            {store.fundky.milestones
              .slice()
              .sort((a, b) => a.goal - b.goal)
              .filter(m => store.getDonationTotal() <= m.goal)
              .map(milestone => (
                <div key={milestone.id} className={`p-2 ${styles.bgDark} my-2 rounded-lg`}>
                  <h1 className="text-leet dark:text-yellow-500 font-bold">
                    {milestone.description.description}
                  </h1>
                  <p className={`${styles.textColorDescription}`}>
                    {getMilestoneOptions(milestone)}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* Bidwars / Polls List */}
        <div
          className={`row-span-1 rounded-md ${styles.bgLight} flex flex-col overflow-hidden border-2 border-slate-500`}
        >
          <h1
            className={`${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-between items-center px-3`}
          >
            <div className="flex justify-start items-center gap-2">
              <div className="w-5 h-5 rotate-90 scale-x-[-1]">
                <ChartBarIcon />
              </div>
              <>{t('poll')}</>
            </div>
            {isAdmin > 2 && (
              <div
                className="w-5 h-5 scale-x-[-1] cursor-pointer"
                onClick={() => handleClickEvent2()}
              >
                <Cog6ToothIcon />
              </div>
            )}
          </h1>
          <div className="w-full row-span-1 overflow-auto px-2">
            {store.fundky.polls.map(poll => (
              <div key={poll.id} className={`p-2 ${styles.bgDark} my-2 rounded-lg`}>
                <h1 className="flex justify-between font-bold text-leet dark:text-yellow-500">
                  {poll.description.title}
                  <div className="w-fit h-fit rounded-full bg-green-200 text-green-800 border-green-800 dark:border-green-500 border px-3 text-xs flex justify-center items-center gap-2">
                    {poll.status}
                    <div className="w-2 h-2 bg-green-800 rounded-full" />
                  </div>
                </h1>
                <div className="flex flex-col">
                  {getPollOptions(poll).map(opt => (
                    <div className="text-white">
                      {opt.title}: ${opt.raised}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* Sponsor Info */}
        <div
          className={`row-span-1 rounded-md ${styles.bgLight} flex flex-col overflow-hidden border-2 border-slate-500`}
        >
          <h1
            className={`${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-start items-center gap-2 pl-3`}
          >
            <div className="w-5 h-5">
              <QuestionMarkCircleIcon />
            </div>
            <>{t('sponsors')}</>
          </h1>
          <div
            className={`${styles.bgLight} w-full h-full overflow-auto px-2 p-2 flex flex-col justify-start items-center gap-2`}
          >
            {store.translations[lang].sponsors.sponsors.length > 0 &&
              store.translations[lang].sponsors.sponsors.map((s, idx) => (
                <div
                  className={`w-full ${styles.textColorHeader} ${styles.bgDark} text-sm p-2 rounded-lg`}
                >
                  <div className="w-full flex justify-between">
                    <h1 className={`text-yellow-500 text-sm font-bold`}>
                      {s.name}{' '}
                      <span className="text-red-400">
                        <Timer startTime={store.sponsorTimers[idx]} />
                      </span>
                    </h1>
                    <div className="w-12 h-5 flex justify-end cursor-pointer">
                      {store.sponsorTimers[idx] ? (
                        <StopIcon onClick={() => store.stopTimer(1, idx)} />
                      ) : (
                        <PlayIcon onClick={() => store.startTimer(1, idx)} />
                      )}
                      {store.sponsorTimers[idx] && (
                        <ArrowPathIcon onClick={() => store.resetTimer(1, idx)} />
                      )}
                    </div>
                  </div>
                  {s.info.map(i => (
                    <p className="indent-8 mb-4 last:mb-0">{i}</p>
                  ))}
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* Column 3 */}
      <div className="hidden col-span-1 h-[96vh] lg:grid grid-rows-3 gap-2 my-4">
        {/* Twitch Feed */}
        {/* <div className={`row-span-1 ${styles.bgLight} flex flex-col`}>
          <iframe
            title="twitch vod"
            src="https://player.twitch.tv/?channel=noresetspeedrun&parent=host.noreset.tv&muted=true"
            height="100%"
            width="100%"
            allowFullScreen="true"
          />
        </div> */}
        {/* Chat Feed */}
        <div className={`row-span-2 rounded-md ${styles.bgLight} flex flex-col`}>
          <iframe
            title="twitch chat"
            frameBorder="0"
            scrolling="yes"
            src={twitchChat}
            height="100%"
            width="100%"
          />
        </div>
        {/* Raffle Info */}
        <div
          className={`row-span-1 rounded-md ${styles.bgLight} flex flex-col overflow-hidden border-2 border-slate-500`}
        >
          <h1
            className={`${styles.roundTop} ${styles.textColorHeader} ${styles.bgDark} border-b-2 border-b-slate-500 font-bold flex justify-start items-center gap-2 pl-3`}
          >
            <div className="w-5 h-5">
              <QuestionMarkCircleIcon />
            </div>
            <>{t('raffle')}</>
          </h1>
          <div
            className={`${styles.bgLight} w-full h-full overflow-auto px-2 p-2 flex flex-col justify-start items-center gap-2`}
          >
            {store.translations[lang].raffles.data.length > 0 &&
              store.translations[lang].raffles.data.map((s, idx) => (
                <div
                  className={`${s.isComplete ? 'line-through opacity-40' : ''} w-full ${
                    styles.textColorHeader
                  } ${styles.bgDark} text-sm p-2 rounded-lg`}
                >
                  <div className="w-full flex justify-between">
                    <h1
                      className={`${
                        s.isComplete ? 'text-white' : 'text-yellow-500'
                      }  text-sm font-bold`}
                    >
                      {s.label}{' '}
                      <span className={`${s.isComplete ? 'hidden' : 'text-red-400'}`}>
                        <Timer startTime={store.raffleTimers[idx]} />
                      </span>
                    </h1>
                    <div className="w-20 h-5 flex justify-end cursor-pointer">
                      {s.isComplete ? (
                        <EyeIcon onClick={() => store.undoRaffleInfo(idx)} />
                      ) : (
                        <EyeSlashIcon onClick={() => store.removeRaffleInfo(idx)} />
                      )}
                      {store.raffleTimers[idx] ? (
                        <StopIcon onClick={() => store.stopTimer(2, idx)} />
                      ) : (
                        <PlayIcon onClick={() => store.startTimer(2, idx)} />
                      )}
                      {store.raffleTimers[idx] && (
                        <ArrowPathIcon onClick={() => store.resetTimer(2, idx)} />
                      )}
                    </div>
                  </div>
                  <p>
                    {t('minamount')} {s.minAmount}
                  </p>
                  <p>
                    {t('timeframe')} {s.timeFrame}
                  </p>
                  <div className="flex gap-2">
                    {t('onsite')}{' '}
                    <div className={`w-5 h-5 ${s.isOnsite ? 'text-green-400' : 'text-red-400'}`}>
                      {s.isOnsite ? <CheckCircleIcon /> : <XCircleIcon />}
                    </div>
                  </div>
                  <p className="indent-8 mb-4 last:mb-0">{s.description}</p>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Page);
